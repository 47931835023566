import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
  state = { error: null, errorInfo: null };

  componentDidCatch(error, info) {
    console.error('ErrorBoundary=', error);
    console.info(info);
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, error, errorInfo: info });
    });
  }

  render() {
    if (this.state.error) {
      //render fallback UI
      return (
        <>
          <Button
            type="link"
            onClick={() => {
              Sentry.showReportDialog({ eventId: this.state.eventId });
            }}
          >
            Report Feedback
          </Button>
          {this.props.fallback}
        </>
      );
    }
    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  fallback: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
export default ErrorBoundary;
