import * as Sentry from '@sentry/browser';

export function initializeSentry() {
  //TODO: Add release ?
  if (process.env.IN_PRODUCTION) {
    Sentry.init({
      dsn: 'https://076565819d7e49d082bff4c92862e024@o393635.ingest.sentry.io/5242994',
      environment: 'production',
    });
  }
}

export function setSentryScope(user) {
  if (process.env.IN_PRODUCTION) {
    Sentry.configureScope(scope => {
      scope.setUser({ email: user.email, id: user.id, username: user.username });
    });
  }
}

export function initializeSmartLook(user) {
  if (!process.env.IN_PRODUCTION) return;
  if (!window.smartlook) {
    const o = (window.smartlook = function() {
        o.api.push(arguments);
      }),
      h = document.getElementsByTagName('head')[0];
    const c = document.createElement('script');
    o.api = [];
    c.async = true;
    c.type = 'text/javascript';
    c.charset = 'utf-8';
    c.src = 'https://rec.smartlook.com/recorder.js';
    h.appendChild(c);
  }
  window.smartlook('init', 'bc8724624462983fb05580efe065c184beb123d3');
  initializeSmartlookUser(user);
}

function initializeSmartlookUser(user) {
  if (!user) return null;
  window.smartlook('identify', user.id, {
    name: `${user.first_name} ${user.last_name || ''}`,
    email: user.email,
  });
}

export function initializeIntercom(user) {
  if (!window.Intercom) return;
  window.Intercom('boot', {
    app_id: 'k5vuqt4e',
    created_at: user.date_joined,
    name: `${user.first_name} ${user.last_name || ''}`,
    email: user.email,
    user_id: `alex_${user.id}`,
    hide_default_launcher: true,
  });
}

export function openSupport() {
  if (window.Intercom) {
    try {
      window.Intercom('show');
    } catch (e) {
      window.location.href = 'mailto:support@shipmnts.com';
    }
  } else {
    window.location.href = 'mailto:support@shipmnts.com';
  }
}
