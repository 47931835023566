import { sortOnKeys } from 'Base/stores/Utils';

const LICENSE_KEY =
  process.env.REACT_APP_PSPDFKIT_LICENSE_KEY && process.env.REACT_APP_PSPDFKIT_LICENSE_KEY.trim();
const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;

export function pspdfConfig({ document, jwt, docId }) {
  const standaloneConfig = {
    enableAutomaticLinkExtraction: false,
    licenseKey: LICENSE_KEY,
    overrideMemoryLimit: 1024,
    //disableOpenParameters: true, try this and benchmark whether it increases the speed or not?
    // standaloneInstancesPoolSize: 2,
    // disableOpenParameters: true,
    document,
  };
  return { ...standaloneConfig, baseUrl };
}

export const toStartOfDate = date => {
  const cloneDate = new Date(+date);
  cloneDate.setHours(0, 0, 0);
  return cloneDate;
};
export const toEndOfDate = date => {
  const cloneDate = new Date(+date);
  cloneDate.setHours(23, 59, 59);
  return cloneDate;
};
export const toUnixTimestamp = date => {
  return (date.getTime() / 1000) | 0;
};
export const dateFromUnixTimestamp = epoc => {
  return new Date(epoc * 1000);
};

export function getOCR(boundingBox, characters) {
  let characterList = characters.filter(character => {
    const { coordinates } = character;
    const x_median = (coordinates[0] + coordinates[2]) / 2;
    const y_median = (coordinates[1] + coordinates[3]) / 2;
    return (
      x_median >= boundingBox.left &&
      x_median <= boundingBox.right &&
      y_median >= boundingBox.top &&
      y_median <= boundingBox.bottom
    );
  });

  characterList.sort(sortOnKeys(['top', 'left']));
  //TODO: Handling of watermark characters
  //TODO: When Vertical overlap  logic is not enough. Ex: ZIM

  if (characterList.length > 0) {
    let textLines = characterList.slice(1).reduce(
      (textLines, character) => {
        //TODO: get the line with max(overlap)
        const overlappingLine = textLines.find(textLine => {
          const minYA = Math.min(...textLine.map(t => t.coordinates[1]));
          const maxYA = Math.max(...textLine.map(t => t.coordinates[3]));
          const [, minYB, , maxYB] = character.coordinates;
          return isVerticalOverlapping([minYA, maxYA], [minYB, maxYB], 25);
        });
        if (overlappingLine) {
          overlappingLine.push(character);
        } else {
          textLines.push([character]);
        }
        return textLines;
      },
      [[characterList[0]]]
    );

    textLines.sort((t1, t2) => {
      const minYA = Math.min(...t1.map(t => t.coordinates[1]));
      const minYB = Math.min(...t1.map(t => t.coordinates[1]));
      return minYA - minYB;
    });
    textLines.forEach(tl => {
      tl.sort(sortOnKeys(['left']));
    });

    textLines = textLines.map(textLine => {
      return textLine.reduce(
        (result, character) => {
          if (!result.previousCharacter) result.previousCharacter = character;
          if (result.previousCharacter.word_id === character.word_id) {
            result.ocr += character.token;
          } else {
            result.ocr = result.ocr + ' ' + character.token;
          }
          result.previousCharacter = character;
          return result;
        },
        { ocr: '', previousCharacter: null }
      ).ocr;
    });

    return textLines.join('\n');
  } else return '';
}

function isVerticalOverlapping(yRangeA, yRangeB, percent = 25) {
  let yStart = Math.max(yRangeA[0], yRangeB[0]),
    yEnd = Math.min(yRangeA[1], yRangeB[1]);

  if (
    yEnd - yStart &&
    ((yEnd - yStart) / Math.min(yRangeA[1] - yRangeA[0], yRangeB[1] - yRangeB[0])) * 100 > percent
  ) {
    return true;
  }
  return false;
}

export function asyncWrap(promise) {
  return promise.then(result => [null, result]).catch(err => [err]);
}

export function downloadBlob({ blob, fileName = 'download' }) {
  const a = document.createElement('a');
  a.href = blob;
  a.style.display = 'none';
  a.download = fileName;
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(blob);
}
//TODO:check if !NaN
export const number_format = (val, decimals = 2) => parseFloat(+val).toFixed(decimals);

export const noop = () => {};
