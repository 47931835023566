import axios from 'axios';
import { message } from 'antd';

let unauthorizedInterceptor;
let timeoutInterceptor;
// let notFoundInterceptor;

export async function signOutUser() {
  resetAxiosDefaults();
}

export function setAxiosDefaults(token, callbackFnSignOut) {
  axios.defaults.timeout = 30000;
  axios.defaults.baseURL = process.env.REACT_APP_DOC_MANAGER_URL;
  axios.defaults.headers.common['Authorization'] = `Token ${token}`;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';
  // axios.defaults.validateStatus = _ => true;
  unauthorizedInterceptor = axios.interceptors.response.use(undefined, error => {
    if (error.response && error.response.status === 401) {
      console.error('signing out');
      signOutUser();
      if (callbackFnSignOut) callbackFnSignOut();
    }
    return Promise.reject(error);
  });
  timeoutInterceptor = axios.interceptors.response.use(
    config => config,
    error => {
      if (error.code === 'ECONNABORTED') {
        message.error('Server is taking to long to respond, Please try again!', 4);
        console.error(`A timeout happend on url ${error.config.url}`);
      }
      return Promise.reject(error);
    }
  );
}

export function resetAxiosDefaults() {
  delete axios.defaults.headers.common['Authorization'];
  axios.interceptors.response.eject(unauthorizedInterceptor);
  axios.interceptors.response.eject(timeoutInterceptor);
  // axios.interceptors.response.eject(notFoundInterceptor);
}
