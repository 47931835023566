export const sortOnKeys = keysArray => (a, b) => {
  return keysArray.reduce((result, key) => {
    if (result) return result;
    if (a[key] > b[key]) return 1;
    else if (a[key] < b[key]) return -1;
    return 0;
  }, 0);
};

export const delay = (ms = 100) => new Promise(resolve => setTimeout(() => resolve(), ms));

export function convertToJSON(array) {
  return array.reduce((hashmap, value) => {
    hashmap.set(value.id, value);
    return hashmap;
  }, new Map());
}

export function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
}

export function getUnique(array) {
  return [...new Set(array)];
}

export function downloadFile(fileURL, fileName) {
  let save = document.createElement('a');
  save.href = fileURL;
  save.target = '_blank';
  let filename = fileName || fileURL.substring(fileURL.lastIndexOf('/') + 1);
  save.download = filename;
  if (
    navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
    navigator.userAgent.search('Chrome') < 0
  ) {
    document.location = save.href;
    // window event not working here
  } else {
    let evt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    save.dispatchEvent(evt);
    (window.URL || window.webkitURL).revokeObjectURL(save.href);
  }
}

export function generateHashCode(s) {
  return s.split('').reduce(function(a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}
