import { types } from 'mobx-state-tree';
import { generateHashCode } from './Utils';

const colors = [
  '#f5222d',
  '#fa541c',
  '#fa8c16',
  '#faad14',
  '#fadb14',
  '#a0d911',
  '#52c41a',
  '#13c2c2',
  '#2f54eb',
  '#722ed1',
  '#eb2f96',
  '#bfbfbf',
];

// The mixin assumes you have a name observable/attribute in the composed model
export const Initials = types.model('Initials').views(self => ({
  get short_name() {
    const initials = self.name.match(/\b\w/g) || [];
    return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
  },
  get color_hash() {
    return colors[generateHashCode(self.short_name) % colors.length];
  },
}));
