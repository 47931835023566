import React from 'react';
import ReactDOM from 'react-dom';
import { LicenseManager } from '@ag-grid-enterprise/core';
// import * as serviceWorker from './serviceWorker';

import ErrorBoundary from 'Base/Errors/components/ErrorBoundary';
import { initializeSentry } from './thirdPartyIntegrations';
import './main.less';
import './main.scss';
import App from './App';
import 'mobx-react/batchingForReactDom';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);

initializeSentry();

ReactDOM.render(
  <ErrorBoundary fallback={<div>App crashed</div>}>
    <App />
  </ErrorBoundary>,
  document.getElementById('root')
);
